// CLIENTS

const slides = document.querySelectorAll(".item");
const buttonPrev = document.querySelector(".buttonPrev");
const buttonNext = document.querySelector(".buttonNext");

let current = 1;
let prev = current - 1;
let next = current + 1;

buttonPrev.addEventListener("click",() => gotoPrev());
buttonNext.addEventListener("click",() => gotoNext());

const gotoPrev = () => current > 0 ? gotoNum(current - 1) : gotoNum(slides.length - 1);

const gotoNext = () => current < slides.length - 1 ? gotoNum(current + 1) : gotoNum(0);

const gotoNum = number => {
    current = number;
    prev = number - 1;
    next = number + 1;

    for (let i = 0; i < slides.length; i++) {
        slides[i].classList.remove("active");
        slides[i].classList.remove("prev");
        slides[i].classList.remove("next");
    }

    if (next == slides.length) {
        next = 0;
    }

    if (prev == -1) {
        prev = slides.length - 1;
    }

    slides[current].classList.add("active");
    slides[prev].classList.add("prev");
    slides[next].classList.add("next");
}

//CLIENTS MOBILE
const slidesMobile = document.querySelectorAll(".itemMobile");
const buttonPrevMobile = document.querySelector(".buttonPrevMobile");
const buttonNextMobile = document.querySelector(".buttonNextMobile");

let currentMobile = 0;
buttonPrevMobile.addEventListener("click", () => gotoPrevMobile());
buttonNextMobile.addEventListener("click", () => gotoNextMobile());

const gotoPrevMobile = () => currentMobile > 0 ? gotoNumMobile(currentMobile - 1) : gotoNumMobile(slidesMobile.length - 1);

const gotoNextMobile = () => currentMobile < slidesMobile.length - 1 ? gotoNumMobile(currentMobile + 1) : gotoNumMobile(0);

const gotoNumMobile = number => {
    currentMobile = number;

    for (let i = 0; i < slidesMobile.length; i++) {
        slidesMobile[i].classList.remove("active");
    }

    slidesMobile[currentMobile].classList.add("active");
}
